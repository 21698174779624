<template>
  <div id="finishedInLedger">
    <el-dialog
      :title="finishedInLedgerFormTitle"
      width="680px"
      :visible.sync="finishedInLedgerDialogVisible"
      :close-on-click-modal="false"
      @close="finishedInLedgerDialogClose"
    >
      <el-form
        ref="finishedInLedgerFormRef"
        :model="finishedInLedgerForm"
        :rules="finishedInLedgerFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="日期" prop="inDate">
              <el-date-picker v-model="finishedInLedgerForm.inDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="finishedInLedgerForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格/装量" prop="spec">
              <el-input v-model="finishedInLedgerForm.spec" placeholder="请输入规格/装量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产批号" prop="batchNum">
              <el-input v-model="finishedInLedgerForm.batchNum" placeholder="请输入生产批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位" prop="unit">
              <el-input v-model="finishedInLedgerForm.unit" placeholder="请输入单位" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="finishedInLedgerForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验单号" prop="testNum">
              <el-input v-model="finishedInLedgerForm.testNum" placeholder="请输入检验单号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="finishedInLedgerForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="finishedInLedgerDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="finishedInLedgerFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.name" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="finishedInLedgerPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inDate">{{ scope.row.inDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="spec" label="规格/装量" />
      <el-table-column prop="batchNum" label="生产批号" />
      <el-table-column prop="unit" label="单位" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="testNum" label="检验单号" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="finishedInLedgerPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addFinishedInLedger, deleteFinishedInLedger, updateFinishedInLedger, selectFinishedInLedgerInfo, selectFinishedInLedgerList } from '@/api/storage/finishedInLedger'

export default {
  data () {
    return {
      finishedInLedgerDialogVisible: false,
      finishedInLedgerFormTitle: '',
      finishedInLedgerForm: {
        id: '',
        inDate: '',
        name: '',
        spec: '',
        batchNum: '',
        unit: '',
        quantity: '',
        testNum: '',
        remarks: ''
      },
      finishedInLedgerFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      finishedInLedgerPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  created () {
    selectFinishedInLedgerList(this.searchForm).then(res => {
      this.finishedInLedgerPage = res
    })
  },
  methods: {
    finishedInLedgerDialogClose () {
      this.$refs.finishedInLedgerFormRef.resetFields()
    },
    finishedInLedgerFormSubmit () {
      if (this.finishedInLedgerFormTitle === '成品入库总账详情') {
        this.finishedInLedgerDialogVisible = false
        return
      }
      this.$refs.finishedInLedgerFormRef.validate(async valid => {
        if (valid) {
          if (this.finishedInLedgerFormTitle === '新增成品入库总账') {
            await addFinishedInLedger(this.finishedInLedgerForm)
          } else if (this.finishedInLedgerFormTitle === '修改成品入库总账') {
            await updateFinishedInLedger(this.finishedInLedgerForm)
          }
          this.finishedInLedgerPage = await selectFinishedInLedgerList(this.searchForm)
          this.finishedInLedgerDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.finishedInLedgerFormTitle = '新增成品入库总账'
      this.finishedInLedgerDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteFinishedInLedger(row.id)
        if (this.finishedInLedgerPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.finishedInLedgerPage = await selectFinishedInLedgerList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.finishedInLedgerFormTitle = '修改成品入库总账'
      this.finishedInLedgerDialogVisible = true
      this.selectFinishedInLedgerInfoById(row.id)
    },
    handleInfo (index, row) {
      this.finishedInLedgerFormTitle = '成品入库总账详情'
      this.finishedInLedgerDialogVisible = true
      this.selectFinishedInLedgerInfoById(row.id)
    },
    selectFinishedInLedgerInfoById (id) {
      selectFinishedInLedgerInfo(id).then(res => {
        this.finishedInLedgerForm.id = res.id
        this.finishedInLedgerForm.inDate = res.inDate
        this.finishedInLedgerForm.name = res.name
        this.finishedInLedgerForm.spec = res.spec
        this.finishedInLedgerForm.batchNum = res.batchNum
        this.finishedInLedgerForm.unit = res.unit
        this.finishedInLedgerForm.quantity = res.quantity
        this.finishedInLedgerForm.testNum = res.testNum
        this.finishedInLedgerForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectFinishedInLedgerList(this.searchForm).then(res => {
        this.finishedInLedgerPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectFinishedInLedgerList(this.searchForm).then(res => {
        this.finishedInLedgerPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectFinishedInLedgerList(this.searchForm).then(res => {
        this.finishedInLedgerPage = res
      })
    }
  }
}
</script>

<style>
</style>
