import axios from '@/common/axios'
import qs from 'qs'

export function addFinishedInLedger (data) {
  return axios({
    method: 'post',
    url: '/storage/finishedInLedger/add',
    data: qs.stringify(data)
  })
}

export function deleteFinishedInLedger (id) {
  return axios({
    method: 'delete',
    url: '/storage/finishedInLedger/delete/' + id
  })
}

export function updateFinishedInLedger (data) {
  return axios({
    method: 'put',
    url: '/storage/finishedInLedger/update',
    data: qs.stringify(data)
  })
}

export function selectFinishedInLedgerInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/finishedInLedger/info/' + id
  })
}

export function selectFinishedInLedgerList (query) {
  return axios({
    method: 'get',
    url: '/storage/finishedInLedger/list',
    params: query
  })
}
